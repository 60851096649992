<template>
  <v-card>
    <v-card-title>
      {{ $t('actions.deleteX', [$t('indices.index')]) }} (DQ: {{ value }})
      <close-button @click="$emit('cancel')"></close-button>
    </v-card-title>
    <v-divider class="mt-0"></v-divider>
    <v-card-text class="pt-5">
      <div v-html="$t('indices.deleteMessage')"></div>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        @click="confirmDelete"
      >
        <v-icon left>
          delete_forever
        </v-icon>
        {{ $t('actions.delete') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import CloseButton from "@/components/stocks/CloseButton"
  export default {
    name: "ConfirmDeleteIndexDialog",
    components: {CloseButton},
    props: {
      value: {
        type: String,
        required: true,
      },
    },
    methods: {
      confirmDelete() {
        this.$emit('confirmDelete', this.value)
        this.$emit('cancel')
      },
    },
  }
</script>

<style scoped>

</style>
