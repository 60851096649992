<template>
  <v-text-field
    hide-details
    flat
    :label="label"
    outlined
    :value="value"
    append-outer-icon="search"
    class="shrink mx-2"
    @input="$emit('input', $event)"
    @keyup.enter="$emit('search', $event)"
    @click:append-outer="$emit('search', $event)"
  >
  </v-text-field>
</template>
<script>
  export default {
    name: 'SearchField',
    props: {
      value: {
        type: String,
        default: '',
      },
      searchLabel: {
        type: String,
        required: false,
        default: null,
      },
    },
    computed: {
      label() {
        return this.searchLabel || this.$t('actions.search')
      },
    },
  }
</script>
