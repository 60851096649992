<template>
  <v-card>
    <v-card-title>
      <h2>{{ $t('actions.createX', [$t('indices.index')]) }}</h2>
      <close-button @click="cancel"></close-button>
    </v-card-title>
    <v-divider class="mt-0"></v-divider>
    <v-card-text>
      <index-editor
        v-model="index"
        :is-insert="true"
      ></index-editor>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        large
        color="primary"
        @click="create"
      >
        <v-icon
          left
        >
          check
        </v-icon>
        {{ $t('actions.create') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  import IndexEditor from "./IndexEditor"
  import CloseButton from "@/components/stocks/CloseButton"

  export default {
    name: "CreateIndexDialog",
    components: {CloseButton, IndexEditor},
    data() {
      return {
        index: {
          dq: "",
          ids: { isin: "", wkn: "", vwd: null, opiand: null, ric: null, ariva: null, figi: null },
          name: "",
          country: null,
          stocks: [],
        },
      }
    },
    methods: {
      create() {
        if (!this.index.ids.isin.match(/^[A-Z]{2}[A-Z0-9]{9}[0-9]$/)
          || !this.index.ids.wkn.match(/^[A-Z0-9]{6}$/)
          || !this.index.name) {
          this.$store.commit('SHOW_SNACKBAR', { text: this.$t('index.invalidInput') })
        } else {
          this.$emit('onIndexCreation', this.index)
          this.cancel()
        }
      },
      cancel() {
        this.resetInputs()
        this.$emit('cancel')
      },
      resetInputs() {
        this.index = {
          dq: "",
          ids: { isin: "", wkn: "", vwd: null, opiand: null, ric: null, ariva: null, figi: null },
          name: "",
          country: null,
          stocks: [],
          derived: {},
        }
      },
    },
  }
</script>

<style scoped>

</style>
