<template>
  <v-container class="pl-0 pr-0">
    <v-row
      class="mt-4"
    >
      <v-col cols="12">
        <h2>{{ $t('indices.listedStocks') }}</h2>
      </v-col>
      <v-col
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
      >
        <v-text-field
          v-model="stockSearch"
          hide-no-data
          append-icon="search"
          :label="$t('actions.search')"
        ></v-text-field>
      </v-col>
      <v-col
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
      >
        <v-autocomplete
          v-model="newStockDQ"
          :items="newStockSuggestions"
          item-value="dq"
          item-text="name"
          :search-input.sync="newStockSearch"
          :loading="newStockSearchLoading"
          :label="$t('actions.addX', [$t('stocks.stock')])"
          :hint="$t('indices.addStockHint')"
          hide-no-data
          append-icon="search"
          append-outer-icon="add"
          @click:append="getStockSuggestions"
          @click:append-outer="addNewStock"
          @keyup.enter="getStockSuggestions"
        >
          <template v-slot:no-data>
            No results found! Press 'Enter' to refresh search
          </template>
          <template v-slot:item="{ item }">
            {{ item.name }} ({{ item.dq }})
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        style="margin-top: -12px;"
      >
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(stock, i) in displayedStocks"
        :key="i"
        class="pb-2"
        xs="12"
        md="6"
        lg="4"
      >
        <v-list-item
          color="theme"
          class="stock-item"
        >
          <v-list-item-content>
            <v-list-item-title :title="stockNames[stock]">
              {{ stockNames[stock] }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ stock }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon @click="removeStock(stock)">
            <v-icon>cancel</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-col>
      <v-col
        v-if="filteredStocks.length"
        cols="12"
      >
        <v-pagination
          v-model="stockPage"
          class="ml-auto mr-auto"
          :length="Math.ceil(filteredStocks.length / stocksPerPage)"
          :total-visible="5"
        ></v-pagination>
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <h4>No stocks found!</h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'IndexStockList',
    props: {
      stocks: {
        type: Array,
        required: true,
      },
      stockNames: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        stockSearch: null,
        stockPage: 1,
        stocksPerPage: 15,
        stockSearchCount: null,

        newStockDQ: null,
        newStockSearch: null,
        newStockSuggestions: [],
        newStockSearchLoading: false,
      }
    },
    computed: {
      filteredStocks() {
        return this.stocks
          .filter(stock =>
            this.stockSearch == null || stock.toUpperCase().includes(this.stockSearch.toUpperCase())
            || this.stockNames[stock].toUpperCase().includes(this.stockSearch.toUpperCase()),
          ).sort((a, b) => {
            if (this.stockNames[a].toUpperCase() < this.stockNames[b].toUpperCase()) return -1
            else if (this.stockNames[a].toUpperCase() > this.stockNames[b].toUpperCase()) return 1
            else return 0
          })
      },
      displayedStocks() {
        let startPos = (this.stockPage - 1) * this.stocksPerPage
        return this.filteredStocks.slice(startPos, startPos + this.stocksPerPage)
      },
    },
    watch: {
      newStockSearch(newVal) {
        if (newVal == null || newVal === '') {
          this.newStockDQ = null
          this.newStockSuggestions = []
        }
      },
    },
    methods: {
      ...mapActions(['fetchStocksByQueryFilter']),

      onRefresh() {
        this.newStockSearch = null
        this.stockSearch = null
      },
      removeStock(stock) {
        this.$emit('removeStock', stock)
      },
      addNewStock() {
        let newDQ = this.newStockDQ == null ? this.newStockSearch : this.newStockDQ

        this.$emit(
          'appendStock',
          {
            dq: newDQ,
            onSuccessCallback: () => {
              this.newStockDQ = null
              this.newStockSearch = null
              this.stockSearch = null
            },
          },
        )
      },
      getStockSuggestions() {
        if (this.newStockSearch == null || this.newStockSearch.length < 2) {
          this.$store.commit('SHOW_SNACKBAR', {text: 'Please provide more details! (at least 2 chars)'})
        } else {
          this.newStockSearchLoading = true
          this.fetchStocksByQueryFilter({
            search: this.newStockSearch,
            page: 1,
            limit: 15,
            fields: ['name'],
          }).then(response => {
            if (response.data.data instanceof Array) {
              if (response.data.data.length === 0) {
                this.$store.commit('SHOW_SNACKBAR', {text: 'No search results! Please modify your search and try again.'})
              }
              this.$set(this, 'newStockSuggestions', response.data.data
                .filter(entry => !this.stocks.includes(entry.dq)),
              )
            }
          }).catch(() => {
            this.$set(this, 'newStockSuggestions', [])
          }).finally(() => {
            this.newStockSearchLoading = false
          })
        }
      },
    },
  }
</script>

<style scoped>
.stock-item {
  border-radius: 5px;
}

.stock-item.theme--dark {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.stock-item.theme--light {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.stock-item .v-list-item__icon {
  cursor: pointer;
}
</style>
