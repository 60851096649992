<template>
  <div class="toolbar">
    <v-row
      align="center"
      align-content="center"
      justify="start"
    >
      <v-col
        class="shrink"
        style="white-space: nowrap"
      >
        <h1>{{ title }}</h1>
      </v-col>

      <slot
        v-if="!disableSearch"
        name="preSearch"
      ></slot>
      <slot
        v-if="!disableSearch"
        name="search"
      >
        <search-field
          :value="search"
          :search-label="searchLabel"
          @input="$emit('update:search', $event)"
          @search="$emit('search', $event)"
          @keydown.enter="$emit('search', $event)"
        ></search-field>
      </slot>

      <slot name="actions"></slot>
    </v-row>

    <v-progress-linear
      v-if="loading"
      indeterminate
      color="yellow darken-2"
      absolute
      top
    ></v-progress-linear>
    <v-row class="my-0">
      <v-divider
        class="my-5"
      ></v-divider>
    </v-row>
  </div>
</template>

<script>
  import SearchField from "@/components/stocks/SearchField"
  export default {
    components: {SearchField},
    props: {
      title: {
        type: String,
        required: false,
        default: null,
      },
      search: {
        type: String,
        required: false,
        default: null,
      },
      searchLabel: {
        type: String,
        required: false,
        default: null,
      },
      disableSearch: {
        type: Boolean,
        required: false,
        default: false,
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  }
</script>
