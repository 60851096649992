<template>
  <v-container
    fluid
    grid-list-xl
  >
    <toolbar
      :title="$t('indices.indices')"
      :search.sync="search"
    >
      <template
        v-slot:actions
      >
        <v-col class="shrink">
          <v-btn
            large
            @click="showAddIndexDialog"
          >
            <v-icon
              color="accent"
              left
            >
              add_circle_outline
            </v-icon>
            {{ $t('actions.createX', [$t('indices.index')]) }}
          </v-btn>
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="selectedIndex"
            large
            color="primary"
            @click="updateIndex"
          >
            <v-icon left>
              save
            </v-icon>
            {{ $t('actions.save') }}
          </v-btn>
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="selectedIndex"
            large
            @click="resetChanges"
          >
            <v-icon
              color="accent"
              left
            >
              undo
            </v-icon>
            {{ $t('actions.reset') }}
          </v-btn>
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="selectedIndex"
            large
            @click="reactivate"
          >
            <v-icon
              color="warning"
              left
            >
              sync
            </v-icon>
            Re-Derive
          </v-btn>
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="selectedIndex"
            large
            @click="showConfirmDeleteDialog"
          >
            <v-icon
              color="error"
              left
            >
              delete_forever
            </v-icon>
            {{ $t('actions.delete') }}
          </v-btn>
        </v-col>
      </template>
    </toolbar>

    <v-row>
      <v-col
        xs="12"
        sm="6"
        md="4"
        lg="3"
        xl="3"
      >
        <index-search
          ref="search"
          :indices="indices"
          :query="search"
          @onIndexSelection="onIndexSelection"
          @resetSearch="search = ''"
        ></index-search>
      </v-col>
      <v-col
        v-if="selectedIndex"
        xs="12"
        sm="6"
        md="8"
        lg="7"
        xl="6"
      >
        <v-card>
          <v-card-text>
            <index-editor
              ref="editor"
              v-model="newSelectedIndex"
              :is-insert="false"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="createIndexDialog"
      width="800"
    >
      <create-index-dialog
        @onIndexCreation="onIndexCreation"
        @cancel="createIndexDialog = false"
      />
    </v-dialog>
    <v-dialog
      v-if="selectedIndex"
      v-model="confirmDeleteIndexDialog"
      width="500"
    >
      <confirm-delete-index-dialog
        v-model="selectedIndex.dq"
        @cancel="confirmDeleteIndexDialog = false"
        @confirmDelete="onIndexDeleted"
      />
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import IndexEditor from "./IndexEditor"
  import CreateIndexDialog from "./CreateIndexDialog"
  import ConfirmDeleteIndexDialog from "./ConfirmDeleteIndexDialog"
  import IndexSearch from "./IndexSearch"
  import Toolbar from "@/components/common/Toolbar"

  export default {
    name: "Indices",
    components: {
      Toolbar,
      IndexSearch,
      ConfirmDeleteIndexDialog,
      CreateIndexDialog,
      IndexEditor,
    },
    data() {
      return {
        indices: [],
        selectedIndex: null,
        newSelectedIndex: null,

        createIndexDialog: false,
        confirmDeleteIndexDialog: false,

        search: '',
      }
    },
    watch: {
      selectedIndex(newValue) {
        this.newSelectedIndex = (newValue == null) ? null : this.cloneObject(newValue)
      },
    },
    mounted() {
      this.getAllIndices()
    },
    methods: {
      ...mapActions(['fetchAllIndices', 'deleteIndex', 'addIndex', 'reactivateIndex']),
      cloneObject(obj) {
        return JSON.parse(JSON.stringify(obj))
      },
      showAddIndexDialog() {
        this.createIndexDialog = true
      },
      showConfirmDeleteDialog() {
        this.confirmDeleteIndexDialog = true
      },
      onIndexSelection(index) {
        this.selectedIndex = index
        this.$nextTick(() => {
          if (index != null) this.$refs['editor'].onRefresh(index.dq)
        })
      },
      getAllIndices() {
        let promise = this.fetchAllIndices()
        promise.then(response => {
          if (response.data != null && response.data.data != null && response.data.data instanceof Array) {
            this.indices = response.data.data.map(index => this.enrichWithMissingStockNames(index))
          }
        })
        return promise
      },
      enrichWithMissingStockNames(index) {
        index.stocks.forEach(stock => {
          if (index.derived == null) index.derived = {stockNames: {}}
          else if (index.derived.stockNames == null) index.derived.stockNames = {}

          if (index.derived.stockNames[stock] === undefined) {
            index.derived.stockNames[stock] = '- Name missing -'
          }
        })
        return index
      },
      onIndexCreation(index) {
        if(this.indices.find(el => el.dq === index.dq)) {
          this.$store.commit('SHOW_SNACKBAR', { text: this.$t('indices.productExistsAlready') })
        } else {
          this.addIndex(index).then(() => {
            this.$store.commit('SHOW_SNACKBAR', { text: this.$t('indices.successfulCreation', [index.name, index.dq]) })

            this.getAllIndices().then(() => {
              this.$set(this, 'search', '')
              this.$set(this, 'selectedIndex', this.indices.find(el => el.dq === index.dq))
              this.$refs['search'].update(index.dq)
            })
          }).catch(() => {
            this.$store.commit('SHOW_SNACKBAR', { text: this.$t('indices.productExistsAlready')} )
          })
        }
      },
      onIndexDeleted(indexDq) {
        this.deleteIndex(indexDq).then(() => {
          this.$store.commit('SHOW_SNACKBAR', { text: this.$t('indices.successfulDelete') })

          this.getAllIndices().then(() => {
            this.$set(this, 'selectedIndex', null)
            if(this.$refs['search'] != null) this.$refs['search'].update(null)
          })
        }).catch(() => {
          this.$store.commit('SHOW_SNACKBAR', { text: this.$t('indices.errorOnDelete') })
        })
      },
      resetChanges() {
        this.selectedIndex = this.cloneObject(this.selectedIndex)
      },
      updateIndex() {
        if (!this.newSelectedIndex.ids.isin.match(/^[A-Z]{2}[A-Z0-9]{9}[0-9]$/) || !this.newSelectedIndex.ids.wkn.match(/^[A-Z0-9]{6}$/)
          || !this.newSelectedIndex.name) {
          this.$store.commit('SHOW_SNACKBAR', { text: this.$t('indices.invalidInputs') })
        } else {
          this.addIndex(this.newSelectedIndex).then(() => {
            this.$store.commit('SHOW_SNACKBAR', { text: this.$t('indices.successfulUpdate', [this.newSelectedIndex.name]) })
            let position = this.indices.findIndex(index => index.dq === this.newSelectedIndex.dq)
            this.$set(this.indices, position, this.newSelectedIndex)
            this.$refs['search'].update(this.newSelectedIndex.dq)
          }).catch(() => {
            this.$store.commit('SHOW_SNACKBAR', { text: this.$t('indices.errorOnUpdate') })
          })
        }
      },
      reactivate() {
        let dq = this.selectedIndex.dq
        this.reactivateIndex(dq).then((response) => {
          this.$set(this, 'selectedIndex', this.enrichWithMissingStockNames(response.data.data))
          this.$store.commit('SHOW_SNACKBAR', {text: `Succesfully re-derived index with DQ ${dq}`})
        })
      },
    },
  }
</script>

<style scoped>
</style>
