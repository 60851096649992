<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="newValue.dq"
          label="DQ"
          readonly
          disabled
        />
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="newValue.name"
          :rules="nameRules"
          label="Name"
          @input="emitChange"
        />
      </v-col>
    </v-row>
    <id-fields
      ref="editIds"
      v-model="newValue.ids"
      :is-insert="isInsert"
      @change="emitChange"
    />
    <v-row class="mt-7">
      <v-col cols="12">
        <v-autocomplete
          v-model="newValue.country"
          :items="countryCodes"
          :search-input.sync="countryFieldSearch"
          :filter="countryCodeFilter"
          item-text="code"
          item-value="code"
          :label="$t('country')"
          @input="emitChange"
        >
          <template v-slot:item="{ item }">
            {{ item.name }} ({{ item.code }})
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <index-stock-list
      v-if="!isInsert"
      ref="stockList"
      :stocks="newValue.stocks"
      :stock-names="newValue.derived.stockNames"
      @removeStock="removeStock"
      @appendStock="addNewStock"
    />
  </v-container>
</template>

<script>
  import countries from 'i18n-iso-countries'
  import langEn from 'i18n-iso-countries/langs/en.json'
  import { mapActions } from 'vuex'
  import IdFields from "../common/IdFields"
  import IndexStockList from "./IndexStockList"

  export default {
    name: "IndexEditor",
    components: {IndexStockList, IdFields},
    props: {
      value: {
        type: Object,
        required: true,
      },
      isInsert: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        nameRules: [
          v => !!v || 'Name must not be empty!',
        ],

        countryCodes: [],
        countryFieldSearch: null,

        currentDQ: null,
      }
    },
    computed: {
      newValue: {
        get() {
          return this.cloneObject(this.value)
        },
        set(val) {
          this.$emit('input', val)
        },
      },
    },
    watch: {
      'newValue.ids.isin'(newVal) {
        this.newValue.dq = `QP00${newVal}`
      },
    },
    created() {
      let alpha3Codes = countries.getAlpha3Codes()
      this.countryCodes = Object
        .keys(alpha3Codes)
        .map(alpha3 => {
          let names = langEn.countries[alpha3Codes[alpha3]]
          return {code: alpha3, name: names instanceof Array ? names.join('/') : names}
        })
    },
    methods: {
      ...mapActions(['fetchSingleStock']),

      cloneObject(obj) {
        return JSON.parse(JSON.stringify(obj))
      },
      emitChange() {
        this.$emit('input', this.newValue)
      },
      onRefresh(dq) {
        if (this.currentDQ !== dq) {
          this.currentDQ = dq
          this.$refs['stockList'].onRefresh()
          this.$refs['editIds'].onRefresh()
        }
      },
      countryCodeFilter(item) {
        return this.countryFieldSearch == null
          || item.code.toUpperCase().includes(this.countryFieldSearch.toUpperCase())
          || item.name.toUpperCase().includes(this.countryFieldSearch.toUpperCase())
      },
      removeStock(stock) {
        let position = this.newValue.stocks.findIndex(dq => dq === stock)
        this.newValue.stocks.splice(position, 1)
        this.emitChange()
      },
      addNewStock({dq, onSuccessCallback}) {
        if (dq != null && !!dq.match(/^QP00[A-Z]{2}[A-Z0-9]*[0-9]$/)) {
          if (this.newValue.stocks.includes(dq)) {
            this.$store.commit('SHOW_SNACKBAR', {text: 'Error: This stock is already listed!'})
          } else {
            this.fetchSingleStock(dq).then(response => {
              this.newValue.stocks.push(dq)
              this.newValue.derived.stockNames[dq] = response.data.data.name
              this.emitChange()
              onSuccessCallback()
              this.$store.commit('SHOW_SNACKBAR', {text: `Added ${response.data.data.name} (${dq}) to the listed stocks!`})
            }).catch(error => {
              if (error.response.status === 404) {
                this.$store.commit('SHOW_SNACKBAR', {text: 'Failed to insert stock. The specified DQ does not exist (yet)!'})
              } else {
                this.$store.commit('SHOW_SNACKBAR', {text: 'Failed to insert stock. Please try again later.'})
              }
            })
          }
        } else {
          this.$store.commit('SHOW_SNACKBAR', {text: 'Invalid DQ!'})
        }
      },
    },
  }
</script>

<style scoped>
</style>
