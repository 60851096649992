<template>
  <v-card>
    <v-card-title>
      {{ $t('search.results') }}
      <span
        v-if="searchResults.length > 0"
        class="pl-2"
      >
        ({{ searchResultStart }} - {{ searchResultEnd }}) of {{ searchResults.length }}
      </span>
    </v-card-title>
    <v-card-text v-if="searchResults.length === 0 && !loading">
      <strong>{{ $t('notification.noProductsFound') }}</strong>
    </v-card-text>
    <v-card-text v-else-if="searchResults.length === 0">
      <strong>{{ $t('notification.loading') }}</strong>
    </v-card-text>
    <v-card-text v-else>
      <v-divider />
      <v-pagination
        v-model="searchPage"
        :length="searchMaxPages"
        :total-visible="5"
      ></v-pagination>
      <v-divider />
      <v-list dense>
        <v-list-item-group
          ref="indices"
          color="primary"
          @change="onIndexChange"
        >
          <v-list-item
            v-for="(index, i) in visibleSearchResults"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon>view_list</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="onIndexSelection(index, i)">
              <v-list-item-title v-text="index.name" />
              <v-list-item-subtitle v-text="index.dq" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'IndexSearch',
    props: {
      indices: {
        type: Array,
        required: true,
      },
      query: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        searchPage: 1,
        searchResultsPerPage: 5,

        selectedIndexPosition: undefined,
        selectedIndexDQ: null,

        loading: true,
      }
    },
    computed: {
      searchResults() {
        let queryUpper = this.query == null ? '' : this.query.toUpperCase()

        return this.indices.filter(index => {
          return queryUpper === ''
            || index.name.toUpperCase().includes(queryUpper)
            || index.dq.toUpperCase().includes(queryUpper)
            || index.ids.wkn.toUpperCase().includes(queryUpper)
        })
      },
      visibleSearchResults() {
        return this.searchResults.slice(this.searchResultStart - 1, this.searchResultEnd)
      },
      searchMaxPages() {
        return Math.max(1, Math.ceil(this.searchResults.length / this.searchResultsPerPage))
      },
      searchResultStart() {
        return (this.searchPage - 1) * this.searchResultsPerPage + 1
      },
      searchResultEnd() {
        return Math.min(this.searchResults.length, this.searchPage * this.searchResultsPerPage)
      },
    },
    watch: {
      searchResults(newValue) {
        if(this.query != null && this.query.length > 0) {
          this.$set(this, 'searchPage', 1)
        }

        this.$nextTick(() => {
          if(newValue.length > 0 && this.$refs['indices'] != null) {
            let pos = this.visibleSearchResults.findIndex(index => index.dq === this.selectedIndexDQ)

            this.selectedIndexPosition = pos >= 0 ? pos : undefined
            this.$refs['indices'].updateInternalValue(this.selectedIndexPosition)
            this.$set(this.$refs['indices'], 'internalLazyValue', this.selectedIndexPosition)
          }
        })
      },
      searchPage() {
        let pos = this.visibleSearchResults.findIndex(index => index.dq === this.selectedIndexDQ)

        this.selectedIndexPosition = pos >= 0 ? pos : undefined
        this.$set(this.$refs['indices'], 'internalLazyValue', this.selectedIndexPosition)
      },
      indices() {
        this.loading = false;
      },
    },
    methods: {
      onIndexSelection(index, position) {
        this.$set(this, 'selectedIndexPosition', position)
        this.$set(this, 'selectedIndexDQ', index != null ? index.dq : null)
        this.$emit('onIndexSelection', index)
      },
      onIndexChange(position) {
        if (position === undefined) this.$set(this.$refs['indices'], 'internalLazyValue', this.selectedIndexPosition)
      },
      update(indexDQ) {
        if (indexDQ == null) {
          this.$nextTick(() => {
            if(this.searchPage > this.searchMaxPages) {
              this.$set(this, 'searchPage', this.searchPage - 1)
            }
            this.$set(this.$refs['indices'], 'internalLazyValue', undefined)
          })
        } else {
          this.$emit('resetSearch')
          this.$nextTick(() => {
            let position = this.indices.findIndex(index => index.dq === indexDQ)
            this.$set(this, 'selectedIndexDQ', indexDQ)
            this.$set(this, 'searchPage', Math.floor(position / this.searchResultsPerPage) + 1)

            this.$nextTick(() => {
              this.$set(this.$refs['indices'], 'internalLazyValue', position % this.searchResultsPerPage)
              this.$refs['indices'].$forceUpdate()
            })
          })
        }
      },
    },
  }
</script>

<style scoped>

</style>
